import { V1 } from '.';
import { Interface, rawDataOrigins } from '../module';
import * as V2 from './interfaces.v2';
export * from './interfaces.v2';

export interface IAdaSimpleObjectScored
  extends Omit<
    V2.IAdaSimpleObjectScored,
    | 'code'
    | 'category'
    | 'wholeCategory'
    | 'conflicts'
    | 'correlatedWith'
    | 'exceptions'
    | 'caveat'
    | 'waitingPeriod'
  > {
  category: TCategory;
  subcategory?: TSubcategory;
  code?: TAdaCode;
  correlatedWith?: ICorelatedWithScored[];
  exceptions?: { [key: string]: IAdaSimpleObjectCustomExceptionScored };
  caveat?: V2.IScoredField<string>[];
  waitingPeriod?: V2.IScoredField<IWaitingPeriod>;
}

export interface IIndexData {
  annualMax?: string;
  basicBenefitLevel?: number;
  carrier?: string;
  groupNumber?: string;
  majorBenefitLevel?: number;
  officeId?: string;
  participation?: string;
  preventiveBenefitLevel?: number;
  sanitizedGroupNumber?: string;
  verificationId?: string;
}

export interface IScoredField<T> extends V2.IScoredField<T> {}

export enum VerificationEnum {
  BREAKDOWN = 'Breakdown',
  RE_VERIFICATION = 'Re-verification',
}

export enum VerificationPdfEnum {
  BREAKDOWN = 'Breakdown',
  RE_VERIFICATION = 'Re-verification',
  PLAN = 'Plan',
}
export type TVerifications = `${VerificationEnum}`;
export type TPdfVerification = `${VerificationPdfEnum}`;
export interface IAdaSimpleObject
  extends Omit<
    V2.IAdaSimpleObject,
    | 'code'
    | 'conflicts'
    | 'category'
    | 'subcategory'
    | 'correlatedWith'
    | 'wholeCategory'
    | 'exceptions'
    | 'caveat'
    | 'waitingPeriod'
  > {
  code?: TAdaCode;
  category: TCategory;
  subcategory?: TSubcategory;
  correlatedWith?: ICorelatedWith[];
  exceptions?: TExceptionsObject;
  caveat?: string[];
  waitingPeriod?: IWaitingPeriod;
  mappingInfo?: IMappingInfoObject[];
}

export type TBenefitKey = TAdaCode | TSubcategory | TCategory;

export interface IBreakdowns extends Omit<V2.IBreakdowns, 'adaCodes'> {
  adaCodes: TAdaCodes;
  mappingInfo?: IMappingInfoObject[];
}

export interface IBreakdownsScored
  extends Omit<V2.IBreakdownsScored, 'adaCodes'> {
  adaCodes: TAdaCodesScored;
}

export type TAdaCodes = {
  [key in TCategory | TSubcategory | TAdaCode]?: IAdaSimpleObject;
};

export type TAdaCodesScored = {
  [key in TCategory | TSubcategory | TAdaCode]?: IAdaSimpleObjectScored;
};

export interface IPlanNote extends V2.IAlertNotes {}

export enum EInternalWarningCode {
  MULTIPLE_PLANS_FOUND = 'MULTIPLE_PLANS_FOUND',
  GROUP_NUMBER_MISSING = 'GROUP_NUMBER_MISSING',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

export type TWarningOrigin = 'clearinghouse' | 'internal';

export interface IPlanErrors {
  action?: string;
  message: string;
  origin: TWarningOrigin;
  code: string | EInternalWarningCode;
  participation?: Interface.TParticipationLevel;
  type: 'error' | 'warning' | 'info';
  ignored?: {
    status: boolean;
    ignoredBy: string;
  };
}

export interface IEmptyPlanErrors {}

export interface ITicket extends Omit<V2.ITicket, 'alertNotes' | 'draft'> {
  draft?: ISchemaPlan[];
  externalClientUrl?: string;
  requestedParticipationLevel?: Interface.TParticipationLevel;
  legacyTicketId?: string;
  nextAppointmentTimeUtc?: string;
  lastAppointmentTimeUtc?: string;
  zipCode?: string;
  carrierPhone?: string;
  patientNumber?: string;
  typeOfVerification?: TVerifications;
  createdAsNotSure?: boolean;
  requestedPayorId?: string;
  // @deplrecated planResponse - replaced with planMessages
  planResponse?: Interface.IPlanErrors | Interface.IEmptyPlanErrors | any;
  verrificationMesages?: Interface.IPlanErrors[];
  isRawPlanAvailable?: boolean;
  planPicker?: IPlanPicker;
  masterDBCarrierId?: string;
  basedOnPlan?: Interface.IBasedOnPlan[];
}

export interface IBasedOnPlan {
  planId: string;
  participation: Interface.TParticipationLevel;
}

export interface IPatient extends Omit<V2.IPatient, 'history'> {
  history?: IHistoryAll[];
  unmappedHistory?: TUnmappedHistory[];
  planEndDate?: string;
  coverageStatus?: TCoverageStatus;
  feeSchedule?: string;
  autoFixedValue?: {
    fixerName: string;
    fixedField: string;
  }[];
}

export interface IEligibilityWithLevel {
  deductibleRemaining?: number;
  insuranceRemaining?: number;
  effectiveDate?: string;
  isActive?: boolean;
  coverageStatus?: TCoverageStatus;
  feeSchedule?: string;
  planEndDate?: string;
  participationLevel: V2.TParticipationLevel;
}

export interface IPatientExtended
  extends Omit<
    IPatient,
    | 'deductibleRemaining'
    | 'insuranceRemaining'
    | 'effectiveDate'
    | 'isActive'
    | 'coverageStatus'
    | 'feeSchedule'
    | 'planEndDate'
  > {
  eligibility: IEligibilityWithLevel[];
}

export interface ITicketWrapper {
  patient: IPatient;
  plan?: ISchemaPlan;
  ticket?: ITicket;
  schemaVersion?: string;
}

export const CoverageStatusArray = [
  'active',
  'terminated',
  'inactive',
  'unknown',
] as const;

export enum CoverageStatus {
  ACTIVE = 'active',
  TERMINATED = 'terminated',
  INACTIVE = 'inactive',
  UNKNOWN = 'unknown',
}

export type TCoverageStatus = (typeof CoverageStatusArray)[number];

export const PlanTypeArray = [
  'dental',
  'medical',
  'dentalExcluded',
  'unknown',
] as const;

export enum PlanType {
  DENTAL = 'dental',
  MEDICAL = 'medical',
  DENTAL_EXCLUDED = 'dentalExcluded',
  UNKNOWN = 'unknown',
}

export type TPlanType = (typeof PlanTypeArray)[number];

export interface IDetails extends V2.IDetails {
  mappingInfo?: IMappingInfoObject[];
  planType?: TPlanType;
}

export interface IDetailsScored extends V2.IDetailsScored {
  planType?: TPlanType;
}

export interface IPayer extends V1.IPayer {
  masterDBCarrierID?: string;
}

export interface ISchemaScoredPlan
  extends Omit<V2.ISchemaPlan, 'benefits' | 'details' | 'caveat' | 'payer'> {
  planNotes?: IPlanNote[];
  benefits: IBreakdownsScored;
  details: IDetailsScored;
  caveat?: V2.IScoredField<string>[];
  planMetadata?: IPlanMetadata;
  formFormat?: IFormFormatScored;
  formIsDirty?: boolean;
  fieldsInfo?: IFieldsInfo;
  payer: IPayer;
}

export interface ISchemaPlan
  extends Omit<V2.ISchemaPlan, 'benefits' | 'details' | 'caveat' | 'payer'> {
  planNotes?: IPlanNote[];
  benefits: IBreakdowns | IBreakdownsScored;
  details: IDetails | IDetailsScored;
  caveat?: string[] | V2.IScoredField<string>[];
  planMetadata?: IPlanMetadata;
  formFormat?: IFormFormat | IFormFormatScored;
  formIsDirty?: boolean;
  fieldsInfo?: IFieldsInfo;
  payer: IPayer;
}

export interface IFieldsInfo {
  missingFieldsCount?: number;
  missingFields?: string[];
  toConfirmFieldsCount?: number;
  toConfirmFields?: string[];
  missingReverificationFieldsCount?: number;
  missingReverificationFields?: string[];
}

export interface IPlanMetadata {
  payerSpecificPlanType?: IPayerSpecificPlanType;
}

export interface IPayerSpecificPlanType {
  matchedValue: PayerSpecificPlanType;
  originalValue: string;
}

export enum PayerSpecificPlanType {
  STANDARD = 'standard',
  HIGH_OPTION = 'highOption',
}

export interface IFormFormat {
  [key: string]:
    | string
    | number
    | IWaitingPeriod
    | boolean
    | V2.TLimitation
    | V2.TAgeLimitation;
}

export interface IFormFormatScored {
  [key: string]: V2.IScoredField<
    | string
    | number
    | IWaitingPeriod
    | boolean
    | V2.TLimitation
    | V2.TAgeLimitation
  >;
}

export interface ICorelatedWithCommon
  extends Omit<V2.ICorelatedWithCommon, 'code' | 'data'> {
  code: TAdaCode;
  data?: {
    [key: string]: any;
  };
}

export interface ICorelatedWithWaitingPeriod
  extends Omit<V2.ICorelatedWithWaitingPeriod, 'code'> {
  code: TAdaCode;
}

export type ICorelatedWith = ICorelatedWithCommon | ICorelatedWithWaitingPeriod;

export interface ICorelatedWithCommonScored
  extends Omit<V2.ICorelatedWithCommonScored, 'code'> {
  code: TAdaCode;
}

export interface ICorelatedWithWaitingPeriodScored
  extends Omit<V2.ICorelatedWithWaitingPeriodScored, 'code'> {
  code: TAdaCode;
}

export type ICorelatedWithScored =
  | ICorelatedWithCommonScored
  | ICorelatedWithWaitingPeriodScored;

export interface IWaitingPeriod extends Omit<V2.IWaitingPeriod, 'codes'> {
  codes?: TAdaCode[];
}

export type IHistoryAll =
  | IHistory
  | IHistoryTeeth
  | IHistorySurfaces
  | IHistoryQuadrants
  | IHistoryArch
  | IHistoryTeethRange;

export type TUnmappedHistory = Omit<IHistory, 'code'> & {
  originalBenefitKey: string;
  isWrongDate?: boolean;
};

export interface IHistory extends Omit<V2.IHistory, 'code'> {
  code: TAdaCode;
  isWrongDate?: boolean;
}
export interface IHistoryTeeth extends IHistory {
  tooth?: V2.TTooth[];
}
export interface IHistorySurfaces extends IHistory {
  surfaces?: { [key: V2.TTooth]: V2.TSurface }[];
}
export interface IHistoryTeethRange extends IHistory {
  teethRange?: V2.TTooth[];
}
export interface IHistoryQuadrants extends IHistory {
  quadrants?: V2.TQuadrant[];
}
export interface IHistoryArch extends IHistory {
  arch?: V2.TArch;
}

export type IAdaSimpleObjectCustomExceptionScored =
  Partial<IAdaSimpleObjectScored>;

export type IAdaSimpleObjectException = Partial<IAdaSimpleObject>;
export type TExceptionsObject = {
  [key: string]: IAdaSimpleObjectException;
};

export interface ISchemaAllPlan
  extends Omit<V2.ISchemaAllPlan, 'plan' | 'ticket' | 'patient'> {
  patient: IPatient;
  plan?: ISchemaPlan | ISchemaPlan[];
  ticket: ITicket;
  rawPlan?: IRawPlan;
  dataSources?: IDataSource[];
  validationNode?: {
    corupted?: boolean;
    coruptionMessage?: string;
  };
}

export interface IDataSource {
  data: string | IRawPlan;
  source: rawDataOrigins;
  participation?: V2.TParticipationLevel;
  planId?: string;
}

export interface IRawPlan {
  type: rawDataOrigins;
  participation?: V2.TParticipationLevel;
  request: any;
  rawData?: any;
  finalData?: any;
  error?: any;
  htmlPdfData?: string;
  planId?: string;
}

export const MappingInfoArray = [
  'Conflict occured',
  'Benefits values where copied from another benefit',
  'Benefit year was established based on the presence of a value with key calendar in maximums in the Onederful plan',
  'Benefit year was established based on the subscriber coverage end value in the Onederful plan',
  'Benefit year was established based on the plan_begin value in the Onederful plan',
  'Plan based on the previous benefit year plan',
  'Plan is cloned',
] as const;

export type FallbackMessage = `Field: ${keyof IAdaSimpleObject} was fallbacked`;

export type TMappingInfo = (typeof MappingInfoArray)[number] | FallbackMessage;

export const feeScheduleOONetwork = [
  'Area UCR',
  'Fixed fee schedule',
  'Maximum Allowable Charge',
  'Percentage of provider’s charges',
  'No benefits/out of network',
];

export type TFeeScheduleOONetwork = (typeof feeScheduleOONetwork)[number];

export interface IMappingInfoObject {
  field:
    | keyof IAdaSimpleObject
    | keyof IBreakdowns
    | keyof IDetails
    | 'whole object';
  message: TMappingInfo;
}

export const CategoryArray = [
  'CAT:Preventative',
  'CAT:Basic',
  'CAT:Major',
  'CAT:Orthodontics',
  'CAT:Adjunctive General Services',
] as const;

export type TCategory = (typeof CategoryArray)[number];

export const SubcategoryArray = [
  'SUB:Diagnostic',
  'SUB:X-Ray',
  'SUB:Exams',
  'SUB:Prophylaxis',
  'SUB:Restorative',
  'SUB:Endodontics',
  'SUB:Periodontics',
  'SUB:Implant Services',
  'SUB:Prosthodontics',
  'SUB:Oral & Maxillofacial Surgery',
  'SUB:Crowns',
  'SUB:Dentures',
  'SUB:Bridges',
] as const;

export type TSubcategory = (typeof SubcategoryArray)[number];

export const AdaCodeArray = [
  'ADA:D0120',
  'ADA:D0140',
  'ADA:D0145',
  'ADA:D0150',
  'ADA:D0160',
  'ADA:D0170',
  'ADA:D0171',
  'ADA:D0180',
  'ADA:D0190',
  'ADA:D0191',
  'ADA:D0210',
  'ADA:D0220',
  'ADA:D0230',
  'ADA:D0240',
  'ADA:D0250',
  'ADA:D0251',
  'ADA:D0260',
  'ADA:D0270',
  'ADA:D0272',
  'ADA:D0273',
  'ADA:D0274',
  'ADA:D0277',
  'ADA:D0290',
  'ADA:D0310',
  'ADA:D0320',
  'ADA:D0321',
  'ADA:D0322',
  'ADA:D0330',
  'ADA:D0340',
  'ADA:D0350',
  'ADA:D0351',
  'ADA:D0360',
  'ADA:D0362',
  'ADA:D0363',
  'ADA:D0364',
  'ADA:D0365',
  'ADA:D0366',
  'ADA:D0367',
  'ADA:D0368',
  'ADA:D0369',
  'ADA:D0370',
  'ADA:D0371',
  'ADA:D0372',
  'ADA:D0373',
  'ADA:D0374',
  'ADA:D0380',
  'ADA:D0381',
  'ADA:D0382',
  'ADA:D0383',
  'ADA:D0384',
  'ADA:D0385',
  'ADA:D0386',
  'ADA:D0387',
  'ADA:D0388',
  'ADA:D0389',
  'ADA:D0391',
  'ADA:D0393',
  'ADA:D0394',
  'ADA:D0395',
  'ADA:D0411',
  'ADA:D0412',
  'ADA:D0414',
  'ADA:D0415',
  'ADA:D0416',
  'ADA:D0417',
  'ADA:D0418',
  'ADA:D0419',
  'ADA:D0421',
  'ADA:D0422',
  'ADA:D0423',
  'ADA:D0425',
  'ADA:D0431',
  'ADA:D0460',
  'ADA:D0470',
  'ADA:D0471',
  'ADA:D0472',
  'ADA:D0473',
  'ADA:D0474',
  'ADA:D0475',
  'ADA:D0476',
  'ADA:D0477',
  'ADA:D0478',
  'ADA:D0479',
  'ADA:D0480',
  'ADA:D0481',
  'ADA:D0482',
  'ADA:D0483',
  'ADA:D0484',
  'ADA:D0485',
  'ADA:D0486',
  'ADA:D0501',
  'ADA:D0502',
  'ADA:D0600',
  'ADA:D0601',
  'ADA:D0602',
  'ADA:D0603',
  'ADA:D0604',
  'ADA:D0605',
  'ADA:D0606',
  'ADA:D0701',
  'ADA:D0702',
  'ADA:D0703',
  'ADA:D0704',
  'ADA:D0705',
  'ADA:D0706',
  'ADA:D0707',
  'ADA:D0708',
  'ADA:D0709',
  'ADA:D0801',
  'ADA:D0999',
  'ADA:D1110',
  'ADA:D1120',
  'ADA:D1201',
  'ADA:D1203',
  'ADA:D1204',
  'ADA:D1205',
  'ADA:D1206',
  'ADA:D1208',
  'ADA:D1310',
  'ADA:D1315',
  'ADA:D1320',
  'ADA:D1321',
  'ADA:D1330',
  'ADA:D1351',
  'ADA:D1352',
  'ADA:D1353',
  'ADA:D1354',
  'ADA:D1355',
  'ADA:D1510',
  'ADA:D1515',
  'ADA:D1516',
  'ADA:D1517',
  'ADA:D1520',
  'ADA:D1525',
  'ADA:D1526',
  'ADA:D1527',
  'ADA:D1550',
  'ADA:D1551',
  'ADA:D1552',
  'ADA:D1553',
  'ADA:D1555',
  'ADA:D1556',
  'ADA:D1557',
  'ADA:D1558',
  'ADA:D1575',
  'ADA:D1999',
  'ADA:D2110',
  'ADA:D2120',
  'ADA:D2130',
  'ADA:D2131',
  'ADA:D2140',
  'ADA:D2150',
  'ADA:D2160',
  'ADA:D2161',
  'ADA:D2210',
  'ADA:D2222',
  'ADA:D2330',
  'ADA:D2331',
  'ADA:D2332',
  'ADA:D2335',
  'ADA:D2336',
  'ADA:D2380',
  'ADA:D2382',
  'ADA:D2390',
  'ADA:D2391',
  'ADA:D2392',
  'ADA:D2393',
  'ADA:D2394',
  'ADA:D2410',
  'ADA:D2420',
  'ADA:D2430',
  'ADA:D2510',
  'ADA:D2520',
  'ADA:D2530',
  'ADA:D2542',
  'ADA:D2543',
  'ADA:D2544',
  'ADA:D2610',
  'ADA:D2620',
  'ADA:D2630',
  'ADA:D2642',
  'ADA:D2643',
  'ADA:D2644',
  'ADA:D2650',
  'ADA:D2651',
  'ADA:D2652',
  'ADA:D2662',
  'ADA:D2663',
  'ADA:D2664',
  'ADA:D2710',
  'ADA:D2712',
  'ADA:D2720',
  'ADA:D2721',
  'ADA:D2722',
  'ADA:D2740',
  'ADA:D2750',
  'ADA:D2751',
  'ADA:D2752',
  'ADA:D2753',
  'ADA:D2780',
  'ADA:D2781',
  'ADA:D2782',
  'ADA:D2783',
  'ADA:D2790',
  'ADA:D2791',
  'ADA:D2792',
  'ADA:D2794',
  'ADA:D2799',
  'ADA:D2810',
  'ADA:D2910',
  'ADA:D2915',
  'ADA:D2920',
  'ADA:D2921',
  'ADA:D2928',
  'ADA:D2929',
  'ADA:D2930',
  'ADA:D2931',
  'ADA:D2932',
  'ADA:D2933',
  'ADA:D2934',
  'ADA:D2940',
  'ADA:D2941',
  'ADA:D2949',
  'ADA:D2950',
  'ADA:D2951',
  'ADA:D2952',
  'ADA:D2953',
  'ADA:D2954',
  'ADA:D2955',
  'ADA:D2957',
  'ADA:D2960',
  'ADA:D2961',
  'ADA:D2962',
  'ADA:D2970',
  'ADA:D2971',
  'ADA:D2975',
  'ADA:D2980',
  'ADA:D2981',
  'ADA:D2982',
  'ADA:D2983',
  'ADA:D2990',
  'ADA:D2991',
  'ADA:D2999',
  'ADA:D3110',
  'ADA:D3120',
  'ADA:D3138',
  'ADA:D3220',
  'ADA:D3221',
  'ADA:D3222',
  'ADA:D3230',
  'ADA:D3233',
  'ADA:D3240',
  'ADA:D3310',
  'ADA:D3320',
  'ADA:D3330',
  'ADA:D3331',
  'ADA:D3332',
  'ADA:D3333',
  'ADA:D3346',
  'ADA:D3347',
  'ADA:D3348',
  'ADA:D3351',
  'ADA:D3352',
  'ADA:D3353',
  'ADA:D3354',
  'ADA:D3355',
  'ADA:D3356',
  'ADA:D3357',
  'ADA:D3410',
  'ADA:D3421',
  'ADA:D3425',
  'ADA:D3426',
  'ADA:D3427',
  'ADA:D3428',
  'ADA:D3429',
  'ADA:D3430',
  'ADA:D3431',
  'ADA:D3432',
  'ADA:D3450',
  'ADA:D3460',
  'ADA:D3470',
  'ADA:D3471',
  'ADA:D3472',
  'ADA:D3473',
  'ADA:D3501',
  'ADA:D3502',
  'ADA:D3503',
  'ADA:D3910',
  'ADA:D3920',
  'ADA:D3950',
  'ADA:D3960',
  'ADA:D3999',
  'ADA:D4210',
  'ADA:D4211',
  'ADA:D4212',
  'ADA:D4220',
  'ADA:D4230',
  'ADA:D4231',
  'ADA:D4240',
  'ADA:D4241',
  'ADA:D4245',
  'ADA:D4249',
  'ADA:D4250',
  'ADA:D4260',
  'ADA:D4261',
  'ADA:D4263',
  'ADA:D4264',
  'ADA:D4265',
  'ADA:D4266',
  'ADA:D4267',
  'ADA:D4268',
  'ADA:D4270',
  'ADA:D4271',
  'ADA:D4273',
  'ADA:D4274',
  'ADA:D4275',
  'ADA:D4276',
  'ADA:D4277',
  'ADA:D4278',
  'ADA:D4283',
  'ADA:D4285',
  'ADA:D4286',
  'ADA:D4320',
  'ADA:D4321',
  'ADA:D4322',
  'ADA:D4323',
  'ADA:D4341',
  'ADA:D4342',
  'ADA:D4346',
  'ADA:D4355',
  'ADA:D4381',
  'ADA:D4910',
  'ADA:D4920',
  'ADA:D4921',
  'ADA:D4999',
  'ADA:D5110',
  'ADA:D5120',
  'ADA:D5130',
  'ADA:D5140',
  'ADA:D5211',
  'ADA:D5212',
  'ADA:D5213',
  'ADA:D5214',
  'ADA:D5221',
  'ADA:D5222',
  'ADA:D5223',
  'ADA:D5224',
  'ADA:D5225',
  'ADA:D5226',
  'ADA:D5227',
  'ADA:D5228',
  'ADA:D5281',
  'ADA:D5282',
  'ADA:D5283',
  'ADA:D5284',
  'ADA:D5286',
  'ADA:D5410',
  'ADA:D5411',
  'ADA:D5421',
  'ADA:D5422',
  'ADA:D5510',
  'ADA:D5511',
  'ADA:D5512',
  'ADA:D5520',
  'ADA:D5610',
  'ADA:D5611',
  'ADA:D5612',
  'ADA:D5620',
  'ADA:D5621',
  'ADA:D5622',
  'ADA:D5630',
  'ADA:D5640',
  'ADA:D5650',
  'ADA:D5660',
  'ADA:D5670',
  'ADA:D5671',
  'ADA:D5710',
  'ADA:D5711',
  'ADA:D5720',
  'ADA:D5721',
  'ADA:D5725',
  'ADA:D5730',
  'ADA:D5731',
  'ADA:D5740',
  'ADA:D5741',
  'ADA:D5750',
  'ADA:D5751',
  'ADA:D5760',
  'ADA:D5761',
  'ADA:D5765',
  'ADA:D5810',
  'ADA:D5811',
  'ADA:D5820',
  'ADA:D5821',
  'ADA:D5850',
  'ADA:D5851',
  'ADA:D5860',
  'ADA:D5861',
  'ADA:D5862',
  'ADA:D5863',
  'ADA:D5864',
  'ADA:D5865',
  'ADA:D5866',
  'ADA:D5867',
  'ADA:D5875',
  'ADA:D5876',
  'ADA:D5899',
  'ADA:D5900',
  'ADA:D5911',
  'ADA:D5912',
  'ADA:D5913',
  'ADA:D5914',
  'ADA:D5915',
  'ADA:D5916',
  'ADA:D5919',
  'ADA:D5922',
  'ADA:D5923',
  'ADA:D5924',
  'ADA:D5925',
  'ADA:D5926',
  'ADA:D5927',
  'ADA:D5928',
  'ADA:D5929',
  'ADA:D5931',
  'ADA:D5932',
  'ADA:D5933',
  'ADA:D5934',
  'ADA:D5935',
  'ADA:D5936',
  'ADA:D5937',
  'ADA:D5951',
  'ADA:D5952',
  'ADA:D5953',
  'ADA:D5954',
  'ADA:D5955',
  'ADA:D5958',
  'ADA:D5959',
  'ADA:D5960',
  'ADA:D5982',
  'ADA:D5983',
  'ADA:D5984',
  'ADA:D5985',
  'ADA:D5986',
  'ADA:D5987',
  'ADA:D5988',
  'ADA:D5991',
  'ADA:D5992',
  'ADA:D5993',
  'ADA:D5994',
  'ADA:D5995',
  'ADA:D5996',
  'ADA:D5999',
  'ADA:D6010',
  'ADA:D6011',
  'ADA:D6012',
  'ADA:D6013',
  'ADA:D6020',
  'ADA:D6040',
  'ADA:D6050',
  'ADA:D6051',
  'ADA:D6052',
  'ADA:D6053',
  'ADA:D6054',
  'ADA:D6055',
  'ADA:D6056',
  'ADA:D6057',
  'ADA:D6058',
  'ADA:D6059',
  'ADA:D6060',
  'ADA:D6061',
  'ADA:D6062',
  'ADA:D6063',
  'ADA:D6064',
  'ADA:D6065',
  'ADA:D6066',
  'ADA:D6067',
  'ADA:D6068',
  'ADA:D6069',
  'ADA:D6070',
  'ADA:D6071',
  'ADA:D6072',
  'ADA:D6073',
  'ADA:D6074',
  'ADA:D6075',
  'ADA:D6076',
  'ADA:D6077',
  'ADA:D6078',
  'ADA:D6079',
  'ADA:D6080',
  'ADA:D6081',
  'ADA:D6082',
  'ADA:D6083',
  'ADA:D6084',
  'ADA:D6085',
  'ADA:D6086',
  'ADA:D6087',
  'ADA:D6088',
  'ADA:D6089',
  'ADA:D6090',
  'ADA:D6091',
  'ADA:D6092',
  'ADA:D6093',
  'ADA:D6094',
  'ADA:D6095',
  'ADA:D6096',
  'ADA:D6097',
  'ADA:D6098',
  'ADA:D6099',
  'ADA:D6100',
  'ADA:D6101',
  'ADA:D6102',
  'ADA:D6103',
  'ADA:D6104',
  'ADA:D6105',
  'ADA:D6106',
  'ADA:D6107',
  'ADA:D6110',
  'ADA:D6111',
  'ADA:D6112',
  'ADA:D6113',
  'ADA:D6114',
  'ADA:D6115',
  'ADA:D6116',
  'ADA:D6117',
  'ADA:D6118',
  'ADA:D6119',
  'ADA:D6120',
  'ADA:D6121',
  'ADA:D6122',
  'ADA:D6123',
  'ADA:D6190',
  'ADA:D6191',
  'ADA:D6192',
  'ADA:D6194',
  'ADA:D6195',
  'ADA:D6197',
  'ADA:D6199',
  'ADA:D6205',
  'ADA:D6210',
  'ADA:D6211',
  'ADA:D6212',
  'ADA:D6214',
  'ADA:D6240',
  'ADA:D6241',
  'ADA:D6242',
  'ADA:D6243',
  'ADA:D6245',
  'ADA:D6250',
  'ADA:D6251',
  'ADA:D6252',
  'ADA:D6253',
  'ADA:D6254',
  'ADA:D6520',
  'ADA:D6530',
  'ADA:D6543',
  'ADA:D6544',
  'ADA:D6545',
  'ADA:D6548',
  'ADA:D6549',
  'ADA:D6600',
  'ADA:D6601',
  'ADA:D6602',
  'ADA:D6603',
  'ADA:D6604',
  'ADA:D6605',
  'ADA:D6606',
  'ADA:D6607',
  'ADA:D6608',
  'ADA:D6609',
  'ADA:D6610',
  'ADA:D6611',
  'ADA:D6612',
  'ADA:D6613',
  'ADA:D6614',
  'ADA:D6615',
  'ADA:D6624',
  'ADA:D6634',
  'ADA:D6710',
  'ADA:D6720',
  'ADA:D6721',
  'ADA:D6722',
  'ADA:D6740',
  'ADA:D6750',
  'ADA:D6751',
  'ADA:D6752',
  'ADA:D6753',
  'ADA:D6780',
  'ADA:D6781',
  'ADA:D6782',
  'ADA:D6783',
  'ADA:D6784',
  'ADA:D6790',
  'ADA:D6791',
  'ADA:D6792',
  'ADA:D6793',
  'ADA:D6794',
  'ADA:D6795',
  'ADA:D6920',
  'ADA:D6930',
  'ADA:D6940',
  'ADA:D6950',
  'ADA:D6970',
  'ADA:D6971',
  'ADA:D6972',
  'ADA:D6973',
  'ADA:D6975',
  'ADA:D6976',
  'ADA:D6977',
  'ADA:D6980',
  'ADA:D6985',
  'ADA:D6999',
  'ADA:D7110',
  'ADA:D7111',
  'ADA:D7120',
  'ADA:D7130',
  'ADA:D7140',
  'ADA:D7210',
  'ADA:D7220',
  'ADA:D7230',
  'ADA:D7240',
  'ADA:D7241',
  'ADA:D7250',
  'ADA:D7251',
  'ADA:D7260',
  'ADA:D7261',
  'ADA:D7270',
  'ADA:D7272',
  'ADA:D7280',
  'ADA:D7281',
  'ADA:D7282',
  'ADA:D7283',
  'ADA:D7284',
  'ADA:D7285',
  'ADA:D7286',
  'ADA:D7287',
  'ADA:D7288',
  'ADA:D7290',
  'ADA:D7291',
  'ADA:D7292',
  'ADA:D7293',
  'ADA:D7294',
  'ADA:D7295',
  'ADA:D7296',
  'ADA:D7297',
  'ADA:D7310',
  'ADA:D7311',
  'ADA:D7320',
  'ADA:D7321',
  'ADA:D7340',
  'ADA:D7350',
  'ADA:D7410',
  'ADA:D7411',
  'ADA:D7412',
  'ADA:D7413',
  'ADA:D7414',
  'ADA:D7415',
  'ADA:D7420',
  'ADA:D7430',
  'ADA:D7431',
  'ADA:D7440',
  'ADA:D7441',
  'ADA:D7450',
  'ADA:D7451',
  'ADA:D7460',
  'ADA:D7461',
  'ADA:D7465',
  'ADA:D7470',
  'ADA:D7471',
  'ADA:D7472',
  'ADA:D7473',
  'ADA:D7480',
  'ADA:D7485',
  'ADA:D7490',
  'ADA:D7509',
  'ADA:D7510',
  'ADA:D7511',
  'ADA:D7520',
  'ADA:D7521',
  'ADA:D7530',
  'ADA:D7540',
  'ADA:D7550',
  'ADA:D7560',
  'ADA:D7610',
  'ADA:D7620',
  'ADA:D7630',
  'ADA:D7640',
  'ADA:D7650',
  'ADA:D7660',
  'ADA:D7670',
  'ADA:D7671',
  'ADA:D7680',
  'ADA:D7710',
  'ADA:D7720',
  'ADA:D7730',
  'ADA:D7740',
  'ADA:D7750',
  'ADA:D7760',
  'ADA:D7770',
  'ADA:D7771',
  'ADA:D7780',
  'ADA:D7810',
  'ADA:D7820',
  'ADA:D7830',
  'ADA:D7840',
  'ADA:D7850',
  'ADA:D7852',
  'ADA:D7854',
  'ADA:D7856',
  'ADA:D7858',
  'ADA:D7860',
  'ADA:D7865',
  'ADA:D7870',
  'ADA:D7871',
  'ADA:D7872',
  'ADA:D7873',
  'ADA:D7874',
  'ADA:D7875',
  'ADA:D7876',
  'ADA:D7877',
  'ADA:D7880',
  'ADA:D7881',
  'ADA:D7899',
  'ADA:D7910',
  'ADA:D7911',
  'ADA:D7912',
  'ADA:D7920',
  'ADA:D7921',
  'ADA:D7922',
  'ADA:D7940',
  'ADA:D7941',
  'ADA:D7942',
  'ADA:D7943',
  'ADA:D7944',
  'ADA:D7945',
  'ADA:D7946',
  'ADA:D7947',
  'ADA:D7948',
  'ADA:D7949',
  'ADA:D7950',
  'ADA:D7951',
  'ADA:D7952',
  'ADA:D7952',
  'ADA:D7953',
  'ADA:D7955',
  'ADA:D7956',
  'ADA:D7957',
  'ADA:D7960',
  'ADA:D7961',
  'ADA:D7962',
  'ADA:D7963',
  'ADA:D7970',
  'ADA:D7971',
  'ADA:D7972',
  'ADA:D7979',
  'ADA:D7980',
  'ADA:D7981',
  'ADA:D7982',
  'ADA:D7983',
  'ADA:D7990',
  'ADA:D7991',
  'ADA:D7993',
  'ADA:D7994',
  'ADA:D7995',
  'ADA:D7996',
  'ADA:D7997',
  'ADA:D7998',
  'ADA:D7999',
  'ADA:D8001',
  'ADA:D8010',
  'ADA:D8020',
  'ADA:D8030',
  'ADA:D8040',
  'ADA:D8050',
  'ADA:D8060',
  'ADA:D8070',
  'ADA:D8080',
  'ADA:D8090',
  'ADA:D8210',
  'ADA:D8220',
  'ADA:D8660',
  'ADA:D8670',
  'ADA:D8680',
  'ADA:D8681',
  'ADA:D8690',
  'ADA:D8691',
  'ADA:D8692',
  'ADA:D8693',
  'ADA:D8694',
  'ADA:D8695',
  'ADA:D8696',
  'ADA:D8697',
  'ADA:D8698',
  'ADA:D8699',
  'ADA:D8701',
  'ADA:D8702',
  'ADA:D8703',
  'ADA:D8704',
  'ADA:D8999',
  'ADA:D9110',
  'ADA:D9120',
  'ADA:D9130',
  'ADA:D9210',
  'ADA:D9211',
  'ADA:D9212',
  'ADA:D9215',
  'ADA:D9219',
  'ADA:D9220',
  'ADA:D9221',
  'ADA:D9222',
  'ADA:D9223',
  'ADA:D9230',
  'ADA:D9239',
  'ADA:D9240',
  'ADA:D9241',
  'ADA:D9242',
  'ADA:D9243',
  'ADA:D9248',
  'ADA:D9310',
  'ADA:D9311',
  'ADA:D9410',
  'ADA:D9420',
  'ADA:D9430',
  'ADA:D9440',
  'ADA:D9450',
  'ADA:D9610',
  'ADA:D9612',
  'ADA:D9613',
  'ADA:D9630',
  'ADA:D9910',
  'ADA:D9911',
  'ADA:D9920',
  'ADA:D9930',
  'ADA:D9931',
  'ADA:D9932',
  'ADA:D9933',
  'ADA:D9934',
  'ADA:D9935',
  'ADA:D9940',
  'ADA:D9941',
  'ADA:D9942',
  'ADA:D9943',
  'ADA:D9944',
  'ADA:D9945',
  'ADA:D9946',
  'ADA:D9947',
  'ADA:D9947',
  'ADA:D9950',
  'ADA:D9951',
  'ADA:D9952',
  'ADA:D9961',
  'ADA:D9970',
  'ADA:D9971',
  'ADA:D9972',
  'ADA:D9973',
  'ADA:D9974',
  'ADA:D9975',
  'ADA:D9985',
  'ADA:D9986',
  'ADA:D9987',
  'ADA:D9990',
  'ADA:D9991',
  'ADA:D9992',
  'ADA:D9993',
  'ADA:D9994',
  'ADA:D9995',
  'ADA:D9996',
  'ADA:D9997',
  'ADA:D9999',
  'NONE',
] as const;

export type TAdaCode = (typeof AdaCodeArray)[number];

// Verification interface

export interface ICoverage extends V2.IPatient {
  isActive?: boolean;
}

export enum IssuerType {
  DENTAL_OFFICE = 'dental office',
}

export type TIssuerType = `${IssuerType}`;

export interface IIssuer extends V2.IClientInfo {
  type: TIssuerType;
  reference: string;
  customization: ICustomization;
}

export interface ICustomization {
  disabledFields: string[];
}
export interface ICarrier {
  name: string;
  payorId?: string;
  levels: V2.TParticipationLevel[];
}

export interface IPlanIndex {
  id: string;
  planId: string;
  masterDBCarrierID: string;
  participation: string;
  groupNumber?: string;
  annualMax?: number;
  basicBenefitLevel?: number;
  majorBenefitLevel?: number;
  preventiveBenefitLevel?: number;
  planPeriod?: string;
  uniqueId?: string;
}

export interface IPlanPickerPerLevel {
  foundPlanIndexes: string[];
  pickedPlanIndex?: string;
  isPickedByUser: boolean;
  isSkipped: boolean;
  indexSearchData: Partial<IPlanIndex>;
  indexRequestData?: Partial<IPlanIndex>;
}

export type IPlanPicker = Partial<
  Record<V2.TParticipationLevel, IPlanPickerPerLevel>
>;

export interface IVerification {
  issuer: IIssuer;
  patient: IPatient;
  patientCoverage: ICoverage;
  carrier: ICarrier;
  drafts: ISchemaPlan[];
  submittedPlan: ISchemaPlan;
  selectedLevel: V2.TParticipationLevel;
}

export enum TeethRegion {
  POSTERIOR = 'POSTERIOR',
  MOLAR = 'MOLAR',
  SECOND_MOLAR = 'SECOND MOLAR',
  ALL = 'ALL',
  BY_REVIEW = 'BY REVIEW',
  NEVER = 'NEVER',
}

export enum BenefitYear {
  JANUARY = 'january',
  FEBRUARY = 'february',
  MARCH = 'march',
  APRIL = 'april',
  MAY = 'may',
  JUNE = 'june',
  JULY = 'july',
  AUGUST = 'august',
  SEPTEMBER = 'september',
  OCTOBER = 'october',
  NOVEMBER = 'november',
  DECEMBER = 'december',
}

export enum ParticipationKeys {
  DEFAULT = 'DEFAULT',
  DELTA = 'DELTA_DENTAL',
  CIGNA = 'CIGNA',
  GUARDIAN = 'GUARDIAN',
  AMERITAS = 'AMERITAS',
  LINCOLN = 'LINCOLN',
  UNITED_HEALTHCARE = 'UHC',
  METLIFE = 'METLIFE',
  PRINCIPAL = 'PRINCIPAL',
  UNITED_CONCORDIA = 'UCCI',
  AETNA = 'AETNA',
  SUNLIFE = 'SUNLIFE',
  HUMANA = 'HUMANA',
  BCBS = 'BCBS',
  ANTHEM = 'ANTHEM',
}

export type ParticipationKeysType = `${ParticipationKeys}`;

export interface OutsourceProcedureHistory {
  dateOfService: string;
  procedureCode: string;
  toothNumber?: string;
  surface?: string;
}
